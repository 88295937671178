import React, { useMemo, useContext } from 'react';
import PropTypes from 'prop-types';

import { Box, Grid, ThemeContext } from 'grommet';
import { Tabs, Tab } from '../Tabs';

import { shuffle } from '../../lib/nodash';
import { getProductPath, isPaint, findMetafieldValue } from '../../lib/product';
import findImage from '../../lib/findImage';
import PaintCollectionCard from '../Paint/PaintCollectionCard';
import ProductCard from '../Product/ProductCard';
import DynamicGrid from '../DynamicPage/DynamicGrid';
import useMobile from '../useMobile';

const WallcoveringsRelatedProducts = ({
  // product,
  // collection,
  // relatedCollections,
  relatedPaintCollections,
  relatedProducts,
  relatedColorProducts,
  limit = 4,
}) => {
  const theme = useContext(ThemeContext);
  const relatedPaintProducts = relatedProducts.filter(isPaint);
  const paintColCount =
    relatedPaintProducts.length > 4 ? 4 : relatedPaintProducts.length;

  const randomColorProducts = useMemo(
    () => shuffle(relatedColorProducts).slice(0, parseInt(limit)),
    [relatedColorProducts, limit]
  );

  const patternColCount =
    randomColorProducts.length > 4 ? 4 : randomColorProducts.length;
  const isMobile = useMobile();
  return (
    <Box>
      <Tabs>
        <Tab plain title="Paint Pairings">
          <DynamicGrid
            columnCount={paintColCount}
            mobileColumnCount={2}
            mobileHorizontalScroll={true}
            mobileHorizontalScrollColumnWidth={'60vw'}
            rowsFillContainerHeight={true}
            gap="5vw"
            items={relatedPaintProducts.map((x) => {
              const paintCollection = relatedPaintCollections.find(
                (c) =>
                  findMetafieldValue('type', c.metafields) === 'Paint' &&
                  c.products.map((p) => p.shopifyId).indexOf(x.shopifyId) > -1
              );

              return {
                reactComponent: (
                  <Box key={x.id} align="center" margin="0 auto" fill>
                    <PaintCollectionCard
                      collection={paintCollection}
                      product={x}
                      title={x.title}
                      fill
                      showShortDescription={true}
                      imageContainerProps={{
                        width: { max: '250px' },
                        fill: 'horizontal',
                      }}
                      containerProps={{
                        pad: {
                          horizontal: 'xsmall',
                          vertical: '5vw',
                        },
                      }}
                      badgeContainerProps={{
                        pad: '1vw',
                      }}
                      srcSetSizes={`(max-width: ${theme.global.breakpoints.small.value}px) 100vw, (max-width: 800px) 70vw, (max-width: 1125px) 18vw, 12vw`}
                    />
                  </Box>
                ),
              };
            })}
          ></DynamicGrid>
        </Tab>
        <Tab plain title="You May Also Like">
          <Grid
            columns={{ count: isMobile ? 2 : patternColCount, size: 'auto' }}
            gap="small"
            pad="medium"
          >
            {randomColorProducts.map((x) => {
              return (
                <Box key={x.id}>
                  <ProductCard
                    collectionImageUrl={
                      findImage(x.media, '_Detail') ||
                      findImage(x.media, '_slide2')
                    }
                    title={x.title}
                    showTitle={false}
                    detailsPath={getProductPath(x)}
                    imageFit="cover"
                    imageFill="contain"
                    pad="none"
                    sizes={`${100 / patternColCount}vw`}
                    imageSrcSetOptions={{
                      ar: '1:1',
                      crop: 'entropy',
                      fit: 'crop',
                    }}
                  />
                </Box>
              );
            })}
          </Grid>
        </Tab>
      </Tabs>
    </Box>
  );
};

WallcoveringsRelatedProducts.propTypes = {
  collection: PropTypes.object,
  product: PropTypes.object,
  relatedProducts: PropTypes.array,
  relatedCollections: PropTypes.array,
  relatedPaintCollections: PropTypes.array,
  relatedColorProducts: PropTypes.array,
  limit: PropTypes.number,
};

export default WallcoveringsRelatedProducts;
